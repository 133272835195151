@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.line-item-container {
    width: 85%;
    padding: 1%;
    margin: 0 auto;
    background-color: #103878;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    justify-content: space-around;
    margin-bottom: 1%;
    cursor: pointer;
}

.line-item-type {
    font-size: 0.5em;
}

.line-item-rate {
    font-size: 0.5em;
}

.line-item-description {
    font-size: 0.5em;
}

.line-item-gstrate {
    font-size: 0.5em;
}