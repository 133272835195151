@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.building-widget {
  min-width: 23%;
  padding: 5%;
  margin: 1%;
  background-color: #e6c3eb;
  border-radius: 10px;
  overflow-wrap: anywhere;
}

.building-widget:hover {
  cursor: pointer;
  background-color: #dbafe0;
}

.form-add-building {
  width: 40vw;
  margin: 0 auto;
  background-color: #274c80;
  border-radius: 3%;
  padding: 2%;
  font-family: "Open Sans", sans-serif;
}

.building-label-field {
  width: 15%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: left;
}

.building-label-field:after {
  content: "*  ";
  color: red;
}

.building-form-control {
  width: 85%;
}

.building-form-group {
  display: flex;
  width: 60%;
}

.form-btn {
  font-size: 0.6em;
  color: white;
  background-color: #102b50;
  border: none;
  border-radius: 3%;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.form-update-building {
  width: 100%;
  font-size: 0.5em;
}