@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.transaction-widget {
    width: 100%;
    display: inline-block;
    font-size: 0.5em;
}

.latePaymentSelector-h4 {
    color: red;
    padding-top: 5px;
}

.csv-download-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3498db;
    /* Change this to your desired background color */
    color: #ffffff;
    /* Change this to your desired text color */
    border: none;
    border-radius: 20px;
    /* Adjust the border-radius to make it more or less rounded */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Animation when clicked */
.csv-download-button:active {
    background-color: #2980b9;
    /* Change this to the color you want on click */
    transform: scale(0.95);
    /* Add a slight scale-down effect on click */
}

.csv-download-button:hover {
    background-color: #2980b9;
    /* Change this to the color you want on hover */
    transform: scale(1.05);
    /* Enlarge the button slightly on hover */
}

.datePicker {
    padding-top: 10px;
    padding-left: 10px;
    font-size: xx-large;
}

.transaction-widget>div>div>div>div>div>table>thead>tr>th:nth-child(1) {
    min-width: 1vw !important;
}

.transaction-widget>div>div>div>div>div>table>thead>tr>th:nth-child(2) {
    min-width: 40vw !important;
}

.transaction-widget>div>div>div>div>div>table>thead>tr>th:nth-child(3) {
    min-width: 1vw !important;
}

.transaction-widget>div>div>div>div>div>table>thead>tr>th:nth-child(7) {
    min-width: 1vw !important;
}

.transaction-widget>div>div>div>div>div>table>thead>tr>th:nth-child(8) {
    min-width: 1vw !important;
}

@media only screen and (max-width: 1600px) {
    .transaction-widget .MuiTableCell-root {
        padding: 0px !important;
        font-size: 1em !important;
    }

    .transaction-widget .MuiButtonBase-root {
        height: 10px !important;
    }
}