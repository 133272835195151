.unit-widget {
  width: 31.33%;
  padding: 6%;
  margin: 1%;
  background-color: #fffbe2;
  border: solid black 3px;
}

.unit-widget:hover {
  cursor: pointer;
  background-color: #dad5b5;
  font-weight: bold;
}

.form-add-unit {
  width: 40vw;
  margin: 0 auto;
  background-color: #274c80;
  border-radius: 3%;
  padding: 2%;
  font-family: "Open Sans", sans-serif;
}

.unit-label-field {
  width: 15%;
  font-size: 0.6em;
  color: white;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-align: left;
}

.unit-label-field:after {
  content: "*  ";
  color: red;
}

.unit-form-control {
  width: 85%;
}

.unit-form-group {
  display: flex;
  width: 60%;
}

.form-btn {
  font-size: 0.6em;
  color: white;
  background-color: #102b50;
  border: none;
  border-radius: 3%;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.form-update-unit {
  width: 100%;
  font-size: 0.5em;
}
