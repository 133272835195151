.discount-rates-container {
    font-size: 0.5em;
    width: 80%;
    margin: 0 auto;
}

.senior-rate-update-btn {
    width: 40%;
    margin: 0 auto;
    background-color: rgb(56, 56, 192);
    color: white;
    font-weight: bold;
    padding: 5% 10%;
    margin-top: 2%;
    border-radius: 8px;
}

.senior-rate-update-btn:hover {
    cursor: pointer;
}

.senior-submit-load {
    margin-top: 2%;
}

.senior-submit-load-hide {
    display: none !important
}