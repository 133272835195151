@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.invoices-widget {
    width: 100%;
    /* overflow-y: scroll;
    white-space: nowrap; */
    min-height: 90vh;
}

.invoices-title {
    font-family: "Montserrat", sans-serif;
}

.invoices-line-items {
    display: flex;
    width: 60%;
    padding: 2%;
    margin: 0 auto;
    align-content: center;
    text-align: center;
    justify-content: space-around;
    border-bottom: solid black 3px;
}

.invoices-added {
    width: 50%;
}

.invoices-not-added {
    width: 50%;
}

.invoices-unpaid-render {
  width: 70%;
  margin: 0 auto;
  font-size: 14px;
  margin-bottom: 2%;
  background-color: pink;
}

.invoices-unpaid-render :hover {
  cursor: pointer;
  background-color: rgb(180, 137, 201);
  transition: 1s background-color;
}

.invoices-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.199) !important;
}

.invoices-modal-open {
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
}

.invoices-loading {
  position: fixed;
  margin-left: 10%;
  z-index: 999;
}

.invoices-loading-hide {
  display: none;
}

.server-invoice-body {
    width: 100% !important;
    /* background-color: rgb(199, 199, 199) !important; */
    color: black !important;
  }
  
  .server-header {
    text-align: left !important;
  }
  
  .server-date-abn-invoice-no {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
  }
  
  .server-column {
    width: 100% !important;
    margin-bottom: 2% !important;
  }
  
  .server-column-abn-invoice-no {
    width: 100% !important;
    text-align: right;
    margin-bottom: 2% !important;
  }
  
  .server-from-to-row {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
  }
  
  .server-column-to-contact {
    width: 100% !important;
    text-align: center;
    margin-bottom: 2% !important;
  }
  
  .server-table {
    border-spacing: 1px !important;
  }
  
  .server-table-readings {
    width: 100% !important;
    padding: 0% !important;
  }
  
  .server-table-readings th {
    background-color: rgb(199, 199, 199) !important;
    padding: 0 !important;
    border: solid 1px black !important;
    padding: 0% !important;
  }
  
  .server-table-readings td {
    border: solid 1px black !important;
    padding: 0% !important;
  }
  
  .server-table-tariff {
    width: 100% !important;
    padding: 0% !important;
  }
  
  .server-table-tariff th {
    background-color: rgb(199, 199, 199) !important;
    padding: 0 !important;
    border: solid 1px black !important;
    padding: 0% !important;
  }
  
  .server-table-tariff td {
    border: solid 1px black !important;
    padding: 0% !important;
  }
  
  .server-table-history {
    width: 100% !important;
    padding: 0% !important;
  }
  
  .server-table-history th {
    background-color: rgb(199, 199, 199) !important;
    padding: 0 !important;
    /* border: 0 !important; */
    border: solid 1px black !important;
    padding: 0% !important;
  }
  
  .server-table-history td {
    /* border: solid 1px black; */
    padding: 0% !important;
    border: 0 !important;
  }
  
  .server-invoice-one {
    width: 20% !important;
    border: 0 !important;
    border-left: solid 1px black !important;
    text-align: right !important;
  }
  
  .server-invoice-two {
    width: 40% !important;
    border: 0 !important;
    font-size: 14px !important;
  }
  
  .server-invoice-three {
    width: 30% !important;
    border: 0 !important;
    /* border-right: solid 1px black !important; */
    text-align: right !important;
    font-size: 14px !important;
  }
  
  .server-invoice-four {
    width: 10% !important;
    border: 0 !important;
    border-left: solid 1px black !important;
    border-right: solid 1px black !important;
    text-align: right !important;
  }