@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.people-widget {
  width: 100%;
  display: inline-block;
  font-size: 0.5em;
}

.people-buttons-row {
  display: flex;
  margin-bottom: 3%;
}

.people-button {
  width: 90%;
  margin-left: 1%;
  margin-right: 1%;
  background-color: rgb(59, 59, 219);
  color: white;
  font-family: "Montserrat", sans-serif;
}

.people-button:hover {
  background-color: rgb(46, 46, 197);
  color: white;
}

@media only screen and (max-width: 1600px) {
  .people-widget .MuiTableCell-root {
    padding: 0px !important;
    font-size: 1em !important;
  } 
    
  .people-widget .MuiButtonBase-root{
    height: 10px !important;
  }
}