@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.unit-management-widget {
    width: 100%;
}

.unit-management-buttons-row {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 3%;
}

.unit-management-button {
    width: 15%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: rgb(59, 59, 219);
    font-size: 1.5vw;
    color: white;
    font-family: "Montserrat", sans-serif;
    border-radius: 10px;
}

.unit-management-button:hover {
    background-color: rgb(33, 33, 153);
    color: white;
}

.btn-return {
    width: 40%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: rgb(9, 9, 75);
    font-size: 1em;
    color: white;
    margin-bottom: 2%;
    font-family: "Montserrat", sans-serif;
}

.btn-return:hover {
    background-color: rgb(6, 6, 46);
    color: white;
}