@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.reading-widget {
  width: 100%;
  display: inline-block;
  font-size: 0.5em;
}

@media only screen and (max-width: 1600px) {
  .reading-widget .MuiTableCell-root {
    padding: 0px !important;
    font-size: 1em !important;
  } 
    
  .reading-widget .MuiButtonBase-root{
    height: 10px !important;
  }
}