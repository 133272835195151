@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.sideBarBody {
  background-color: #fcf4ff;
  width: 12%;
  max-width: 230px;
  min-width: 230px;
  font-family: "Montserrat", sans-serif;
  border-right: solid 3px black;
}

.stickySideBar {
  position: fixed;
  width: 12%;
  max-width: 230px;
  min-width: 230px;
  background-color: #fcf4ff;
  border-right: solid 3px black;
}

.sideBtn {
  color: black !important;
  font-size: 0.45em !important;
}

.tfaBtn {
  color: white !important;
  border: 3px solid white !important;
  border-radius: 14px;
  padding-top: 3 !important;
  padding-bottom: 3 !important;
  padding-right: 4 !important;
  padding-left: 4 !important;
}

.passwordBtn {
  color: white !important;
  border: 3px solid white !important;
  border-radius: 14px;
  padding-top: 3 !important;
  padding-bottom: 3 !important;
  padding-right: 4 !important;
  padding-left: 4 !important;
  margin-left: 20px !important;
}

.tfaBtnClose {
  background-color: rgb(207, 102, 102) !important;
  color: white !important;
  margin-right: 1% !important;
}

.tfaBtnSubmit {
  background-color: rgb(83, 83, 216) !important;
  color: white !important;
}


.timesheetTracker {
  color: black !important;
  font-size: 0.65em;
}

.timesheetTrackerBody {
  color: black !important;
  font-size: 0.55em;
}

.sideBtn:hover {
  background-color: white !important;
  border-color: #424345 !important;
}

.sideBtnBtm {
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtm:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.sideBtnBtmPassword {
  color: black !important;
  font-size: 0.45em !important;
  justify-content: flex-start !important;
}

.sideBtnBtmPassword:hover {
  background-color: white !important;
  font-size: 0.45em !important;
}

.unrelatedObjects {
  margin-top: 10%;
}

.userSettings {
  margin-top: 10%;
}

.icon-title {
  width: 100%;
  font-size: 2em;
  color: #575652;
}

.icon-title:hover {
  color: black;
}

/* @media only screen and (max-width: 1366px) {
  .sideBarBody {
    width: 15%;
  }

  .stickySideBar {
    width: 15%;
  }
} */

@media only screen and (max-width: 978px) {
  .sideBarBody {
    display: none;
  }

  .stickySideBar {
    display: none;
  }

  .sideBtnBtmPassword {
    font-size: 0.7em !important;
    color: white !important;
  }
}
