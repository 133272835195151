@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.loadingProgress {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.dashboard-body {
  background-color: #a696ba;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: black;
  overflow-x: hidden;
}

.dashboard-background {
  width: 100%;
}

.dashboard-outer {
  width: 100%;
  max-height: fit-content;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
}

.dashboard-inner {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
}

.dashboard-title {
  margin: 0 auto;
  font-weight: bold;
}

.dashboard-main-area {
  display: flex;
  flex-direction: row;
}

.dashboard-building-list {
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.dashboard-unit-list {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-update-btn {
  font-size: 0.6em;
  color: white;
  background-color: #102b50;
  border: none;
  border-radius: 3%;
  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 1%;
}

.section-home {
  width: 90%;
  margin: 0 auto;
  color: white;
}

.section-building {
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  border: solid 3px black;
  padding: 1%;
}

.section-discount-rates {
  width: 40%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  border: solid 3px black;
  display: flex;
  align-items: center;
}

.section-line-items {
  width: 60%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  border: solid 3px black;
  display: flex;
  align-items: center;
}

.section-invoices {
  width: 80%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  border: solid 3px black;
}

.invoices-modal-open-wide {
  width: 70% !important;
  max-height: 90vh !important;
  background-color: white;
  border: 2px solid #000;
  margin-left: 10%;
  text-align: center;
  font-weight: bold;
  padding: 1%;
  overflow-y: scroll;
}

.invoices-all-unpaid-render {
  max-width: 31.3%;
  min-width: 31.3%;
  padding: 5%;
  margin: 1%;
  background-color: #e6c3eb;
  border-radius: 10px;
  overflow-wrap: anywhere;
}

.invoices-all-unpaid-render :hover {
  cursor: pointer;
}

.home-generate-invoices-download {
  width: 25%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  margin-top: 5%;
  cursor: pointer;
  background-color: #4e85de;
  color: white;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  font-size: 0.8em;
}

.home-invoice-due-date-picker {
  margin-top: 5%;
  font-size: 0.9em;
  font-weight: bold;
}

.home-missing-readings-title {
  margin-top: 5%;
  font-family: 'Lato', sans-serif;
  font-size: 1.3em;
  font-weight: bold;
}

.home-missing-readings-item {
  font-family: 'Lato', sans-serif;
  font-size: 0.7em;
}

.home-title {
  font-size: 2em;
}

.dlCSV {
  width: 20%;
  margin-top: 3% !important;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
  margin-top: 5%;
  cursor: pointer;
  background-color: #4e85de;
  color: white;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  font-size: 0.7em;
}

@media only screen and (max-width: 1368px) {
  .section-discount-rates {
    width: 70%;
  }
}

@media only screen and (max-width: 978px) {
  .dashboard-title {
    font-size: 2em;
    margin-bottom: 10%;
  }
}
