@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.login-form {
  width: 100%;
  padding: 15%;
  background-color: white;
}

.form-field {
  font-size: 1em;
  width: 100%;
  margin-bottom: 5%;
}

#logo {
  width: 100%;
  display: flex;
}

.btn {
  width: 70%;
  padding-top: 3% !important;
  padding-bottom: 3% !important;
}

.login-btn {
  display: flex;
  justify-content: center;
}

.spinning-icon {
  margin-left: 5%;
}

.spinning-icon-hide {
  display: none;
}

.login-error {
  color: #990000;
  font-size: 0.6em;
  font-weight: bold;
}

.login-error-hide {
  display: hide;
}

.login-success {
  color: #2ad613;
  font-size: 0.6em;
  font-weight: bold;
}

.login-success-hide {
  display: hide;
}

.login-title {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 10%;
}